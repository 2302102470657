import * as _ from 'lodash';
import { Order, Route, Transporter } from '@amzn/gsf-dispatcher-schema';
import { OrderLevelAssignmentInput } from '../graphqlGenerated/graphql';
import OrderHelper from './OrderHelper';
import RouteHelper from './RouteHelper';
import StringHelper from './StringHelper';
import TimeHelper, { MINUTE } from './TimeHelper';
import TransporterHelper from './TransporterHelper';
import orderStore from '../stores/orderStore';
import routeStore from '../stores/routeStore';
import transporterStore from '../stores/transporterStore';

const UNASSIGNED_TRANSPORTER_NAME = 'Unassigned';
export default class AssignmentHelper {
  static determineAssignmentConfirmationMessages(): string[] {
    const messages: string[] = [];

    const selectedOrderIds = AssignmentHelper.getValidSelectedOrderIds();
    const orderIdMap: Map<string, Order> = new Map();
    selectedOrderIds.forEach((orderId) =>
      orderIdMap.set(orderId, AssignmentHelper.findOrderById(orderId))
    );
    const validSelectedOrderIds = selectedOrderIds.filter(
      (orderId) => !!orderIdMap.get(orderId)
    );
    const unplannedOrders: Order[] = [];
    const ordersInARoute: Order[] = [];
    validSelectedOrderIds.forEach((orderId) => {
      const order = orderIdMap.get(orderId);
      if (order.routeId) {
        ordersInARoute.push(order);
      } else {
        unplannedOrders.push(order);
      }
    });

    // look for scenarios where we don't need any confirmation messages

    // 1. no confirmations message if all selected orders are for unplanned orders and none of the unplanned orders are currently assigned
    if (
      ordersInARoute.length === 0 &&
      !_.some(unplannedOrders, (unplannedOrder) =>
        OrderHelper.isOrderAssigned(unplannedOrder)
      )
    ) {
      // all selected orders are for unplanned orders
      return [];
    }

    const uniqueRouteIds = _.uniq(ordersInARoute.map((o) => o.routeId));
    const routeMap: Map<string, Route> = new Map();
    uniqueRouteIds.forEach((routeId) => {
      const route = RouteHelper.findRouteById(routeId);
      routeMap.set(routeId, route);
    });

    const { selectedTransporterId } = transporterStore;
    const transporter = TransporterHelper.findTransporterById(
      selectedTransporterId
    );
    const transporterName = TransporterHelper.getTransporterName(transporter);

    // look for order in route assignment
    uniqueRouteIds.forEach((uniqueRouteId) => {
      const route = routeMap.get(uniqueRouteId);
      const allOrdersForRoute = route.orders || [];
      const selectedOrdersForRoute = ordersInARoute.filter(
        (o) => o.routeId === uniqueRouteId
      );
      if (allOrdersForRoute.length !== selectedOrdersForRoute.length) {
        messages.push(
          `Route ${route.routeName}: Route split with only ${selectedOrdersForRoute.length} of ` +
            StringHelper.makeCountDisplay(allOrdersForRoute.length, 'order') +
            ' selected'
        );
      } else {
        const routeEstimatedTimeMilliSec = route.plannedDurationSeconds
          ? route.plannedDurationSeconds * 1000
          : undefined;
        const transporterSessionEndTimeMilliSec =
          transporter.transporterSession?.expectedSessionEndTime;
        const timeBufferMillis = 0 * MINUTE;
        if (routeEstimatedTimeMilliSec && transporterSessionEndTimeMilliSec) {
          const transporterEndTimeMilliSec = Number(
            transporterSessionEndTimeMilliSec
          );
          const transporterShiftTimeRemaining =
            transporterEndTimeMilliSec - Date.now();
          if (
            transporterShiftTimeRemaining - routeEstimatedTimeMilliSec <
            -1 * timeBufferMillis
          ) {
            messages.push(
              `Route ${
                route.routeName
              }: route duration is ${TimeHelper.secondsToHoursString(
                Math.abs(route.plannedDurationSeconds)
              )}, but transporter ` +
                `block ends in ${TimeHelper.hoursTillEndTime(
                  transporter.transporterSession?.expectedSessionEndTime
                )}`
            );
          }
        }
      }
      const selectedOrdersForRouteGroupedByTransporterName = _.groupBy(
        selectedOrdersForRoute,
        (orderForRoute) => {
          const transporterName = TransporterHelper.getTransporterName(
            AssignmentHelper.getTransporterForOrderInRoute(route, orderForRoute)
          );
          return transporterName
            ? transporterName
            : UNASSIGNED_TRANSPORTER_NAME;
        }
      );
      const selectedOrderTransporterNames = _.keys(
        selectedOrdersForRouteGroupedByTransporterName
      );
      selectedOrderTransporterNames.forEach((currentTransporterName) => {
        const selectedRouteOrdersForTransporterName =
          selectedOrdersForRouteGroupedByTransporterName[
            currentTransporterName
          ];
        if (
          allOrdersForRoute.length ===
          selectedRouteOrdersForTransporterName.length
        ) {
          if (currentTransporterName !== UNASSIGNED_TRANSPORTER_NAME) {
            messages.push(
              `Route ${route.routeName}: all orders` +
                ` changing from '${currentTransporterName}' to '${transporterName}'`
            );
          }
        } else {
          messages.push(
            `Route ${route.routeName}: ` +
              StringHelper.makeCountDisplay(
                selectedRouteOrdersForTransporterName.length,
                'order'
              ) +
              ` changing from '${currentTransporterName}' to '${transporterName}'`
          );
        }
      });
    });

    // look for unplanned order reassignment
    const unplannedOrdersGroupedByCurrentTransporterName = _.groupBy(
      unplannedOrders,
      (unplannedOrder) => {
        const transporterName = TransporterHelper.getTransporterName(
          unplannedOrder.transporter
        );
        return transporterName ? transporterName : UNASSIGNED_TRANSPORTER_NAME;
      }
    );
    const unplannedOrderTransporterNames = _.keys(
      unplannedOrdersGroupedByCurrentTransporterName
    );
    unplannedOrderTransporterNames.forEach((currentTransporterName) => {
      const unplannedOrdersForTransporterName =
        unplannedOrdersGroupedByCurrentTransporterName[currentTransporterName];
      messages.push(
        StringHelper.makeCountDisplay(
          unplannedOrdersForTransporterName.length,
          'unplanned order'
        ) + ` changing from '${currentTransporterName}' to '${transporterName}'`
      );
    });

    return messages;
  }

  static gatherOrderIdsForAssignment(): OrderLevelAssignmentInput[] {
    const selectedOrderIds = AssignmentHelper.getValidSelectedOrderIds();
    return selectedOrderIds.map((orderId) =>
      AssignmentHelper.findOrderLevelAssignmentById(orderId)
    );
  }

  static findOrderById(orderId: string): Order {
    const unplannedOrder = OrderHelper.findOrderById(orderId);
    if (unplannedOrder) {
      // unplanned order
      return unplannedOrder;
    }
    return RouteHelper.findOrderInRouteById(orderId);
  }

  private static getTransporterForOrderInRoute(
    route: Route,
    order: Order
  ): Transporter {
    if (OrderHelper.isOrderAssigned(order)) {
      return order.transporter;
    }
    if (RouteHelper.isRouteAssigned(route)) {
      return route.transporter;
    }
    return undefined;
  }

  private static getValidSelectedOrderIds(): string[] {
    const { selectedOrderIds } = orderStore;
    return selectedOrderIds.filter(
      (orderId) => !!AssignmentHelper.findOrderById(orderId)
    );
  }

  private static findOrderLevelAssignmentById(
    orderId: string
  ): OrderLevelAssignmentInput {
    const order = AssignmentHelper.findOrderById(orderId);
    return {
      externalReferenceId: order.externalReferenceId,
      orderId: order.orderId,
      routeId: order.routeId,
    };
  }

  static determineUnassignmentConfirmationMessages(): string[] {
    const messages: string[] = [];

    const selectedOrderIds = AssignmentHelper.getValidSelectedOrderIds();
    const orderIdMap: Map<string, Order> = new Map();
    selectedOrderIds.forEach((orderId) =>
      orderIdMap.set(orderId, AssignmentHelper.findOrderById(orderId))
    );
    const validSelectedOrderIds = selectedOrderIds.filter(
      (orderId) => !!orderIdMap.get(orderId)
    );
    const unplannedOrders: Order[] = [];
    const ordersInARoute: Order[] = [];
    validSelectedOrderIds.forEach((orderId) => {
      const order = orderIdMap.get(orderId);
      if (order.routeId) {
        ordersInARoute.push(order);
      } else {
        unplannedOrders.push(order);
      }
    });

    const uniqueRouteIds = _.uniq(ordersInARoute.map((o) => o.routeId));
    const routeMap: Map<string, Route> = new Map();
    uniqueRouteIds.forEach((routeId) => {
      const route = RouteHelper.findRouteById(routeId);
      routeMap.set(routeId, route);
    });

    ordersInARoute.forEach((order) => {
      const route = routeMap.get(order.routeId);
      messages.push(
        `Order ${order.orderId} from route ${route.routeName} will be un-assigned.`
      );
    });
    unplannedOrders.forEach((order) => {
      messages.push(`Unplanned order ${order.orderId} will be un-assigned.`);
    });
    return messages;
  }
}
