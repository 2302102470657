import { BehaviorSubject, Observable, merge } from 'rxjs';
import { PREFIX_ORDER_ID } from '../util/FilterConstants';
import { reaction } from 'mobx';
import FilterHelper from '../util/FilterHelper';
import filterStore from 'stores/filterStore';
import orderStore from '../stores/orderStore';
import searchByIdReactor from './searchByIdReactor';
import searchByIdStore from '../stores/searchByIdStore';
import siteReactor from './siteReactor';

class FilterReactor {
  private selectedOrderFilterCodesChangeSubject = new BehaviorSubject<boolean>(
    false
  );

  selectedOrderFilterCodesChange$: Observable<boolean> =
    this.selectedOrderFilterCodesChangeSubject.asObservable();

  private selectedTransporterFilterCodesChangeSubject =
    new BehaviorSubject<boolean>(false);

  selectedTransporterFilterCodesChange$: Observable<boolean> =
    this.selectedTransporterFilterCodesChangeSubject.asObservable();
  constructor() {
    reaction(
      () => {
        return filterStore.selectedOrderFilterCodes;
      },
      (filterCodes: string[]) => {
        this.selectedOrderFilterCodesChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return filterStore.selectedTransporterFilterCodes;
      },
      (filterCodes: string[]) => {
        this.selectedTransporterFilterCodesChangeSubject.next(true);
      }
    );

    /**
     * listen to changes in the selected site and reset the order filtering, partial search string, and
     * searchByIdValues.
     * */
    const resetOrderFilteringSubscriptionForSiteChange = merge(
      siteReactor.selectedSiteChange$.pipe()
    );
    resetOrderFilteringSubscriptionForSiteChange.subscribe(async (event) => {
      filterStore.setSelectedTransporterFilterCodes([]);
      filterStore.setSelectedOrderFilterCodes([]);
      searchByIdStore.setSearchByPartialStringInput('');
      searchByIdStore.setSearchByIdValues([]);
    });

    /**
     * listen to changes to the searchByIdValues and update the filter store's filter codes as well
     * as updating the list of highlighted order ids
     */
    searchByIdReactor.searchByIdValuesChange$.subscribe(async () => {
      const { searchByIdValues } = searchByIdStore;
      filterStore.setSelectedOrderFilterCodes(searchByIdValues);

      const highlightedOrderIds = searchByIdValues
        .map((searchById) => {
          if (FilterHelper.startsWithPrefix(PREFIX_ORDER_ID, searchById)) {
            return FilterHelper.getCodeValue(PREFIX_ORDER_ID, searchById);
          }
          return '';
        })
        .filter((orderId) => orderId !== '');
      orderStore.setHighlightedOrderIds(highlightedOrderIds);
    });
  }
}

export default new FilterReactor();
