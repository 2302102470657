import * as _ from 'lodash';
import {
  Order,
  Route,
  TimeWindow,
  Transporter,
} from '@amzn/gsf-dispatcher-schema';
import OrderHelper from './OrderHelper';
import TimeHelper from './TimeHelper';
import UnitHelper from './UnitHelper';
import routeStore from '../stores/routeStore';

export default class RouteHelper {
  static isAmazonPickupRoute(route: Route): boolean {
    const [amazonIndex, thirdPartyIndex] =
      RouteHelper.getFirstAmazonAndThirdPartyAddressIndices(route);
    return amazonIndex > -1 && thirdPartyIndex === -1;
  }
  static isThirdPartyPickupRoute(route: Route): boolean {
    const [amazonIndex, thirdPartyIndex] =
      RouteHelper.getFirstAmazonAndThirdPartyAddressIndices(route);
    return thirdPartyIndex > -1 && amazonIndex === -1;
  }

  static isAmazonThenThirdPartyPickupRoute(route: Route): boolean {
    const [amazonIndex, thirdPartyIndex] =
      RouteHelper.getFirstAmazonAndThirdPartyAddressIndices(route);
    return (
      amazonIndex > -1 && thirdPartyIndex > -1 && amazonIndex < thirdPartyIndex
    );
  }

  static isThirdPartyThenAmazonPickupRoute(route: Route): boolean {
    const [amazonIndex, thirdPartyIndex] =
      RouteHelper.getFirstAmazonAndThirdPartyAddressIndices(route);
    return (
      amazonIndex > -1 && thirdPartyIndex > -1 && thirdPartyIndex < amazonIndex
    );
  }

  static isRouteAssigned(route: Route): boolean {
    return !!route.transporter?.name;
  }

  static isRouteAssignedOrAllOrdersAssigned(route: Route): boolean {
    const orders = route.orders || [];
    return (
      RouteHelper.isRouteAssigned(route) ||
      (orders.length > 0 &&
        _.every(RouteHelper.getOrders([route]), (order) =>
          OrderHelper.isOrderAssigned(order)
        ))
    );
  }

  static areAllOrdersOnRoutePicked(route: Route): boolean {
    const orders = route.orders || [];
    return (
      orders.length > 0 &&
      _.every(RouteHelper.getOrders([route]), (order) =>
        OrderHelper.isOrderPickedUp(order)
      )
    );
  }

  /**
   * Returns true if the backend has assigned a transporter at the order level which occurs if the route orders
   * are assigned to multiple transporters or partially assigned
   * @param route
   */
  static isRouteAssignmentSplit(route: Route): boolean {
    // the backend will only set a transporter on the order level if the route assignment is split
    return _.some(RouteHelper.getOrders([route]), (order) =>
      OrderHelper.isOrderAssigned(order)
    );
  }

  static getTransporterMap(route: Route): Map<string, Transporter> {
    const map: Map<string, Transporter> = new Map();
    if (route?.transporter?.transporterId) {
      map.set(route.transporter.transporterId, route.transporter);
    }
    for (const order of RouteHelper.getOrders([route])) {
      if (order?.transporter?.transporterId) {
        map.set(order.transporter.transporterId, order.transporter);
      }
    }

    return map;
  }

  static setRouteIdOnRouteOrders(route: Route): void {
    // it is essential that all orders on a route have the route id populated for assignment to work as expected
    // so this method should be called when receiving a route from the backend
    const { routeId } = route;
    const orders = RouteHelper.getOrders([route]);
    orders.forEach((order) => (order.routeId = routeId));
  }

  static calculatePercentSlammed(route: Route): string {
    const orders = RouteHelper.getOrders([route]);
    const numberOrders = orders.length;
    const percent =
      numberOrders === 0
        ? '0'
        : (
            (100 *
              orders.filter((o) => o.packageCount && o.packageCount > 0)
                .length) /
            numberOrders
          ).toFixed(0);
    return `${percent}%`;
  }

  static findRouteById(routeId: string): Route {
    const { routes } = routeStore;
    return routes.find((r) => r.routeId === routeId);
  }

  static findOrderInRouteById(orderId: string): Order {
    const { routes } = routeStore;
    return RouteHelper.getOrders(routes).find((o) => o.orderId === orderId);
  }

  /**
   *
   * @param route
   * @returns the package count of all orders in the route or undefined if unknown (pre SLAM)
   */
  static calculateTotalPackageCount(route: Route): number {
    const orders = RouteHelper.getOrders([route]);
    return _.sum(orders.map((o) => o.packageCount || 1));
  }

  static calculateTotalOrderCount(route: Route): number {
    return RouteHelper.getOrders([route]).length;
  }

  static calculateTotalPackageWeight(route: Route): string {
    const orders = RouteHelper.getOrders([route]);
    return UnitHelper.getOrdersWeight(orders);
  }

  static getDeliveryWindowForRoute(route: Route): string {
    const orders = RouteHelper.getOrders([route]);
    const startDate = OrderHelper.getEarliestOrderDeliveryStartTime(orders);
    const endDate = OrderHelper.getLatestOrderDeliveryEndTime(orders);
    const timeWindow: TimeWindow = {
      startDate,
      endDate,
    };
    return TimeHelper.timeWindowAsString(timeWindow);
  }

  static getPickupWindowForRoute(route: Route): string {
    const orders = RouteHelper.getOrders([route]);
    const startDate = OrderHelper.getEarliestOrderPickupStartTime(orders);
    const endDate = OrderHelper.getLatestOrderPickupEndTime(orders);
    const timeWindow: TimeWindow = {
      startDate,
      endDate,
    };
    return TimeHelper.timeWindowAsString(timeWindow);
  }

  static getOrders(routes: Route[]): Order[] {
    return routes.flatMap((r) => r.orders || []);
  }

  private static getFirstAmazonAndThirdPartyAddressIndices(
    route: Route
  ): number[] {
    const pickupAddresses = OrderHelper.uniquePickupAddresses(
      RouteHelper.getOrders([route])
    );
    const amazonPickupAddress = pickupAddresses.find(
      (address) => !OrderHelper.is3rdPartyPickupAddress(address)
    );
    const amazonPickupAddressIndex = amazonPickupAddress
      ? pickupAddresses.indexOf(amazonPickupAddress)
      : -1;
    const thirdPartyPickupAddress = pickupAddresses.find((address) =>
      OrderHelper.is3rdPartyPickupAddress(address)
    );
    const thirdPartyPickupAddressIndex = thirdPartyPickupAddress
      ? pickupAddresses.indexOf(thirdPartyPickupAddress)
      : -1;
    return [amazonPickupAddressIndex, thirdPartyPickupAddressIndex];
  }
}
