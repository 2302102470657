import { observer } from 'mobx-react';
import Button from '@amzn/meridian/button';
import React from 'react';
import UnassignmentController from '../../controllers/UnassignmentController';
import orderStore from '../../stores/orderStore';
import transporterStore from '../../stores/transporterStore';

export interface UnassignCompProps {}

class UnassignComp extends React.Component<UnassignCompProps> {
  constructor(props: UnassignCompProps) {
    super(props);
  }
  render() {
    const { selectedOrderIds } = orderStore;
    const { selectedTransporterId } = transporterStore;
    return (
      !selectedTransporterId &&
      selectedOrderIds.length > 0 && (
        <Button
          size="medium"
          type="primary"
          onClick={() => UnassignmentController.startUnassignment()}
          propagateClickEvent={false}
        >
          Unassign Selected Order(s)/Route(s)
        </Button>
      )
    );
  }
}

export default observer(UnassignComp);
