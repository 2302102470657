import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: 'Query';
  /** All sites name list */
  getAllSiteNames: SiteNamesPage;
  getAllSites: SitesPage;
  /** Orders Queries */
  getOrder?: Maybe<Order>;
  getOrdersForSite: OrdersPage;
  /** Routes Queries */
  getRoute?: Maybe<Route>;
  getRoutesForSite: RoutesPage;
  /** Sites Queries (Authorization needed) */
  getSite?: Maybe<Site>;
  /** Transporter Queries */
  getTransporter?: Maybe<Transporter>;
  getTransportersForSite: TransportersPage;
};

export type QueryGetAllSiteNamesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGetAllSitesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrderArgs = {
  orderId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type QueryGetOrdersForSiteArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['ID'];
};

export type QueryGetRouteArgs = {
  routeId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type QueryGetRoutesForSiteArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['ID'];
};

export type QueryGetSiteArgs = {
  siteCode: Scalars['ID'];
};

export type QueryGetTransporterArgs = {
  siteCode: Scalars['ID'];
  transporterId: Scalars['ID'];
};

export type QueryGetTransportersForSiteArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['ID'];
};

export type SiteNamesPage = {
  __typename?: 'SiteNamesPage';
  nextToken?: Maybe<Scalars['String']>;
  sites?: Maybe<Array<Maybe<SiteName>>>;
};

export type SiteName = {
  __typename?: 'SiteName';
  serviceAreaId?: Maybe<Scalars['String']>;
  siteCode?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
};

export type SitesPage = {
  __typename?: 'SitesPage';
  nextToken?: Maybe<Scalars['String']>;
  sites?: Maybe<Array<Maybe<Site>>>;
};

export type Site = {
  __typename?: 'Site';
  autoAssignEnabled?: Maybe<Scalars['Boolean']>;
  autoPlanningEnabled?: Maybe<Scalars['Boolean']>;
  isDspSite?: Maybe<Scalars['Boolean']>;
  pickupAddress?: Maybe<Address>;
  pickupLocation?: Maybe<GpsLocation>;
  programTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  regionId?: Maybe<Scalars['String']>;
  routesUpdatedTimeMillis?: Maybe<Scalars['String']>;
  serviceAreaId?: Maybe<Scalars['String']>;
  siteCode: Scalars['ID'];
  siteName?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  transportersUpdatedTimeMillis?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type GpsLocation = {
  __typename?: 'GpsLocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  batched?: Maybe<Scalars['Boolean']>;
  deliveryAddress?: Maybe<Address>;
  deliveryAddressId?: Maybe<Scalars['String']>;
  deliveryLocation?: Maybe<GpsLocation>;
  deliveryWindow?: Maybe<TimeWindow>;
  externalReferenceId?: Maybe<Scalars['String']>;
  itemCount?: Maybe<Scalars['Int']>;
  orderId: Scalars['ID'];
  orderStatus?: Maybe<OrderStatus>;
  originAddressId?: Maybe<Scalars['String']>;
  packageCount?: Maybe<Scalars['Int']>;
  packageGroupId?: Maybe<Scalars['String']>;
  pickupAddress?: Maybe<Address>;
  pickupLocation?: Maybe<GpsLocation>;
  pickupWindow?: Maybe<TimeWindow>;
  routeId?: Maybe<Scalars['String']>;
  siteCode: Scalars['String'];
  slammed?: Maybe<Scalars['Boolean']>;
  trackingIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  transporter?: Maybe<Transporter>;
  trIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
};

export type TimeWindow = {
  __typename?: 'TimeWindow';
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export enum OrderStatus {
  Delivered = 'DELIVERED',
  DeliveredWithException = 'DELIVERED_WITH_EXCEPTION',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  PendingPickup = 'PENDING_PICKUP',
  Placeholder = 'PLACEHOLDER',
}

export type Transporter = {
  __typename?: 'Transporter';
  companyName?: Maybe<Scalars['String']>;
  lastKnownLocation?: Maybe<GpsLocation>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  providerType?: Maybe<TransportationProviderType>;
  remainingOrders?: Maybe<Array<Maybe<Order>>>;
  remainingStops?: Maybe<Array<Maybe<TransporterStop>>>;
  siteCode: Scalars['String'];
  transporterId: Scalars['ID'];
  transporterSession?: Maybe<TransporterSession>;
};

export enum TransportationProviderType {
  Csp = 'CSP',
  Dsp = 'DSP',
  NotSet = 'NOT_SET',
}

export type TransporterStop = {
  __typename?: 'TransporterStop';
  locationId?: Maybe<Scalars['String']>;
  orderIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  stopType?: Maybe<TransporterStopType>;
  taskStartTime?: Maybe<Scalars['String']>;
  trIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TransporterStopType {
  Delivery = 'DELIVERY',
  NotSet = 'NOT_SET',
  Pickup = 'PICKUP',
}

export type TransporterSession = {
  __typename?: 'TransporterSession';
  expectedSessionEndTime?: Maybe<Scalars['String']>;
  providerReservationId?: Maybe<Scalars['String']>;
  schedulingType?: Maybe<TransporterSchedulingType>;
  transportationMode?: Maybe<TransportationMode>;
};

export enum TransporterSchedulingType {
  Block = 'BLOCK',
  Instant = 'INSTANT',
}

export enum TransportationMode {
  Biking = 'BIKING',
  Driving = 'DRIVING',
  Motorcycle = 'MOTORCYCLE',
  NotSet = 'NOT_SET',
  Walking = 'WALKING',
}

export enum WeightUnit {
  Kilograms = 'KILOGRAMS',
  NotSet = 'NOT_SET',
  Pounds = 'POUNDS',
}

export type OrdersPage = {
  __typename?: 'OrdersPage';
  nextToken?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
};

export type Route = {
  __typename?: 'Route';
  orders?: Maybe<Array<Maybe<Order>>>;
  plannedDurationSeconds?: Maybe<Scalars['Int']>;
  routeId: Scalars['ID'];
  routeName?: Maybe<Scalars['String']>;
  routeStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeStops?: Maybe<Array<Maybe<TransporterStop>>>;
  siteCode: Scalars['String'];
  transporter?: Maybe<Transporter>;
  version?: Maybe<Scalars['Int']>;
};

export type RoutesPage = {
  __typename?: 'RoutesPage';
  nextToken?: Maybe<Scalars['String']>;
  routes?: Maybe<Array<Maybe<Route>>>;
};

export type TransportersPage = {
  __typename?: 'TransportersPage';
  nextToken?: Maybe<Scalars['String']>;
  transporters?: Maybe<Array<Maybe<Transporter>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignOrdersToTransporter?: Maybe<AssignmentResponse>;
  /** API's for assignment */
  assignRouteToTransporter?: Maybe<AssignmentResponse>;
  /** Order Mutations */
  createOrder: Order;
  /** Route Mutations */
  createRoute: Route;
  /** Site Mutations */
  createSite: Site;
  /** Transporter Mutations */
  createTransporter: Transporter;
  destroyOrder?: Maybe<Order>;
  destroyRoute?: Maybe<Route>;
  destroySite?: Maybe<Site>;
  destroyTransporter?: Maybe<Transporter>;
  /** API for UI logging */
  uiLogs?: Maybe<Scalars['Int']>;
  /**
   * API for explicit un-assignment
   * Uses the same model and params as the assign api and returns the same response.
   */
  unassignOrdersFromTransporter?: Maybe<AssignmentResponse>;
  unassignRouteFromTransporter?: Maybe<AssignmentResponse>;
  updateOrder: Order;
  updateRoute: Route;
  updateSite: Site;
  updateTransporter: Transporter;
  /** Single query to call to get a EventBridge Ping to the backend */
  warmSite?: Maybe<Scalars['Boolean']>;
};

export type MutationAssignOrdersToTransporterArgs = {
  orders?: InputMaybe<Array<InputMaybe<OrderLevelAssignmentInput>>>;
  siteCode: Scalars['ID'];
  transporterId: Scalars['ID'];
};

export type MutationAssignRouteToTransporterArgs = {
  routeId: Scalars['ID'];
  siteCode: Scalars['ID'];
  transporterId: Scalars['ID'];
};

export type MutationCreateOrderArgs = {
  order?: InputMaybe<OrderInput>;
  orderId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type MutationCreateRouteArgs = {
  route: RouteInput;
  routeId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type MutationCreateSiteArgs = {
  site?: InputMaybe<SiteInput>;
  siteCode: Scalars['ID'];
};

export type MutationCreateTransporterArgs = {
  siteCode: Scalars['ID'];
  transporter: TransporterInput;
  transporterId: Scalars['ID'];
};

export type MutationDestroyOrderArgs = {
  orderId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type MutationDestroyRouteArgs = {
  routeId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type MutationDestroySiteArgs = {
  siteCode: Scalars['ID'];
};

export type MutationDestroyTransporterArgs = {
  siteCode: Scalars['ID'];
  transporterId: Scalars['ID'];
};

export type MutationUiLogsArgs = {
  logs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  siteCode: Scalars['ID'];
};

export type MutationUnassignOrdersFromTransporterArgs = {
  orders?: InputMaybe<Array<InputMaybe<OrderLevelAssignmentInput>>>;
  siteCode: Scalars['ID'];
  transporterId: Scalars['ID'];
};

export type MutationUnassignRouteFromTransporterArgs = {
  routeId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type MutationUpdateOrderArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  order?: InputMaybe<OrderInput>;
  orderId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type MutationUpdateRouteArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  route: RouteInput;
  routeId: Scalars['ID'];
  siteCode: Scalars['ID'];
};

export type MutationUpdateSiteArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  site?: InputMaybe<SiteInput>;
  siteCode: Scalars['ID'];
};

export type MutationUpdateTransporterArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  siteCode: Scalars['ID'];
  transporter?: InputMaybe<TransporterInput>;
  transporterId: Scalars['ID'];
};

export type MutationWarmSiteArgs = {
  siteCode: Scalars['ID'];
};

export type OrderLevelAssignmentInput = {
  externalReferenceId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
  /** TODO these can be made optional or removed altogether once client references are updated */
  packageGroupId?: InputMaybe<Scalars['String']>;
  routeId?: InputMaybe<Scalars['String']>;
};

/** Route/Order Assigment Call Response Model */
export type AssignmentResponse = {
  __typename?: 'AssignmentResponse';
  errorMessage?: Maybe<Scalars['String']>;
  failed?: Maybe<Array<Maybe<Scalars['String']>>>;
  successful?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderInput = {
  batched?: InputMaybe<Scalars['Boolean']>;
  deliveryAddress?: InputMaybe<AddressInput>;
  deliveryLocation?: InputMaybe<GpsLocationInput>;
  deliveryWindow?: InputMaybe<TimeWindowInput>;
  externalReferenceId?: InputMaybe<Scalars['String']>;
  itemCount?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['ID'];
  orderStatus?: InputMaybe<OrderStatus>;
  packageCount?: InputMaybe<Scalars['Int']>;
  packageGroupId?: InputMaybe<Scalars['String']>;
  pickupAddress?: InputMaybe<AddressInput>;
  pickupLocation?: InputMaybe<GpsLocationInput>;
  pickupWindow?: InputMaybe<TimeWindowInput>;
  routeId?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['String'];
  slammed?: InputMaybe<Scalars['Boolean']>;
  trackingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transporter?: InputMaybe<TransporterInput>;
  trIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  weight?: InputMaybe<Scalars['Float']>;
  weightUnit?: InputMaybe<WeightUnit>;
};

export type AddressInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address3?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type GpsLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type TimeWindowInput = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type TransporterInput = {
  companyName?: InputMaybe<Scalars['String']>;
  lastKnownLocation?: InputMaybe<GpsLocationInput>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  providerType?: InputMaybe<TransportationProviderType>;
  remainingOrders?: InputMaybe<Array<InputMaybe<OrderInput>>>;
  remainingStops?: InputMaybe<Array<InputMaybe<TransporterStopInput>>>;
  siteCode: Scalars['String'];
  transporterId: Scalars['ID'];
  transporterSession?: InputMaybe<TransporterSessionInput>;
};

export type TransporterStopInput = {
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stopType?: InputMaybe<TransporterStopType>;
};

export type TransporterSessionInput = {
  expectedSessionEndTime?: InputMaybe<Scalars['String']>;
  providerReservationId?: InputMaybe<Scalars['String']>;
  schedulingType?: InputMaybe<TransporterSchedulingType>;
  transportationMode?: InputMaybe<TransportationMode>;
};

export type RouteInput = {
  orders?: InputMaybe<Array<InputMaybe<OrderInput>>>;
  plannedDurationSeconds?: InputMaybe<Scalars['Int']>;
  routeId: Scalars['ID'];
  routeName?: InputMaybe<Scalars['String']>;
  routeStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  routeStops?: InputMaybe<Array<InputMaybe<TransporterStopInput>>>;
  siteCode: Scalars['String'];
  transporter?: InputMaybe<TransporterInput>;
};

export type SiteInput = {
  autoAssignEnabled?: InputMaybe<Scalars['Boolean']>;
  autoPlanningEnabled?: InputMaybe<Scalars['Boolean']>;
  isDspSite?: InputMaybe<Scalars['Boolean']>;
  pickupAddress?: InputMaybe<AddressInput>;
  pickupLocation?: InputMaybe<GpsLocationInput>;
  programTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regionId?: InputMaybe<Scalars['String']>;
  routesUpdatedTimeMillis?: InputMaybe<Scalars['String']>;
  serviceAreaId?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['ID'];
  siteName?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  transportersUpdatedTimeMillis?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Order Subscriptions */
  subscribeToCreateOrder?: Maybe<Order>;
  /** Route Subscriptions */
  subscribeToCreateRoute?: Maybe<Route>;
  /** Site Subscriptions */
  subscribeToCreateSite?: Maybe<Site>;
  /** Transporter Subscriptions */
  subscribeToCreateTransporter?: Maybe<Transporter>;
  subscribeToDestroyOrder?: Maybe<Order>;
  subscribeToDestroyRoute?: Maybe<Route>;
  subscribeToDestroySite?: Maybe<Site>;
  subscribeToDestroyTransporter?: Maybe<Transporter>;
  subscribeToUpdateOrder?: Maybe<Order>;
  subscribeToUpdateRoute?: Maybe<Route>;
  subscribeToUpdateSite?: Maybe<Site>;
  subscribeToUpdateTransporter?: Maybe<Transporter>;
};

export type SubscriptionSubscribeToCreateOrderArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToCreateRouteArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToCreateSiteArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToCreateTransporterArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToDestroyOrderArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToDestroyRouteArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToDestroySiteArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToDestroyTransporterArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToUpdateOrderArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToUpdateRouteArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToUpdateSiteArgs = {
  siteCode: Scalars['ID'];
};

export type SubscriptionSubscribeToUpdateTransporterArgs = {
  siteCode: Scalars['ID'];
  transporterId?: InputMaybe<Scalars['ID']>;
};

export type AddressFieldsFragment = {
  __typename?: 'Address';
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
};

export type GpsLocationFieldsFragment = {
  __typename?: 'GpsLocation';
  latitude: number;
  longitude: number;
};

export type TimeWindowFieldsFragment = {
  __typename?: 'TimeWindow';
  endDate?: string | null;
  startDate?: string | null;
};

export type TransporterStopFieldsFragment = {
  __typename?: 'TransporterStop';
  stopType?: TransporterStopType | null;
  orderIds?: Array<string | null> | null;
};

export type AssignmentResponseFieldsFragment = {
  __typename?: 'AssignmentResponse';
  successful?: Array<string | null> | null;
  failed?: Array<string | null> | null;
  errorMessage?: string | null;
};

export type UiLogsMutationVariables = Exact<{
  siteCode: Scalars['ID'];
  logs: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type UiLogsMutation = {
  __typename?: 'Mutation';
  uiLogs?: number | null;
};

export type AssignOrdersToTransporterMutationVariables = Exact<{
  orders:
    | Array<InputMaybe<OrderLevelAssignmentInput>>
    | InputMaybe<OrderLevelAssignmentInput>;
  siteCode: Scalars['ID'];
  transporterId: Scalars['ID'];
}>;

export type AssignOrdersToTransporterMutation = {
  __typename?: 'Mutation';
  assignOrdersToTransporter?: {
    __typename?: 'AssignmentResponse';
    successful?: Array<string | null> | null;
    failed?: Array<string | null> | null;
    errorMessage?: string | null;
  } | null;
};

export type UnassignOrdersFromTransporterMutationVariables = Exact<{
  orders:
    | Array<InputMaybe<OrderLevelAssignmentInput>>
    | InputMaybe<OrderLevelAssignmentInput>;
  siteCode: Scalars['ID'];
  transporterId: Scalars['ID'];
}>;

export type UnassignOrdersFromTransporterMutation = {
  __typename?: 'Mutation';
  unassignOrdersFromTransporter?: {
    __typename?: 'AssignmentResponse';
    successful?: Array<string | null> | null;
    failed?: Array<string | null> | null;
    errorMessage?: string | null;
  } | null;
};

export type GetOrdersForSiteQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['ID'];
}>;

export type GetOrdersForSiteQuery = {
  __typename?: 'Query';
  getOrdersForSite: {
    __typename?: 'OrdersPage';
    nextToken?: string | null;
    orders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
  };
};

export type SubscribeToCreateOrderSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToCreateOrderSubscription = {
  __typename?: 'Subscription';
  subscribeToCreateOrder?: {
    __typename?: 'Order';
    batched?: boolean | null;
    externalReferenceId?: string | null;
    itemCount?: number | null;
    orderId: string;
    orderStatus?: OrderStatus | null;
    packageCount?: number | null;
    packageGroupId?: string | null;
    routeId?: string | null;
    siteCode: string;
    slammed?: boolean | null;
    weight?: number | null;
    weightUnit?: WeightUnit | null;
    deliveryAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    deliveryLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    deliveryWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    pickupWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    transporter?: {
      __typename?: 'Transporter';
      name?: string | null;
      phoneNumber?: string | null;
      transporterId: string;
    } | null;
  } | null;
};

export type SubscribeToDestroyOrderSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToDestroyOrderSubscription = {
  __typename?: 'Subscription';
  subscribeToDestroyOrder?: {
    __typename?: 'Order';
    batched?: boolean | null;
    externalReferenceId?: string | null;
    itemCount?: number | null;
    orderId: string;
    orderStatus?: OrderStatus | null;
    packageCount?: number | null;
    packageGroupId?: string | null;
    routeId?: string | null;
    siteCode: string;
    slammed?: boolean | null;
    weight?: number | null;
    weightUnit?: WeightUnit | null;
    deliveryAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    deliveryLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    deliveryWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    pickupWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    transporter?: {
      __typename?: 'Transporter';
      name?: string | null;
      phoneNumber?: string | null;
      transporterId: string;
    } | null;
  } | null;
};

export type SubscribeToUpdateOrderSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToUpdateOrderSubscription = {
  __typename?: 'Subscription';
  subscribeToUpdateOrder?: {
    __typename?: 'Order';
    batched?: boolean | null;
    externalReferenceId?: string | null;
    itemCount?: number | null;
    orderId: string;
    orderStatus?: OrderStatus | null;
    packageCount?: number | null;
    packageGroupId?: string | null;
    routeId?: string | null;
    siteCode: string;
    slammed?: boolean | null;
    weight?: number | null;
    weightUnit?: WeightUnit | null;
    deliveryAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    deliveryLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    deliveryWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    pickupWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    transporter?: {
      __typename?: 'Transporter';
      name?: string | null;
      phoneNumber?: string | null;
      transporterId: string;
    } | null;
  } | null;
};

export type OrderFieldsFragment = {
  __typename?: 'Order';
  batched?: boolean | null;
  externalReferenceId?: string | null;
  itemCount?: number | null;
  orderId: string;
  orderStatus?: OrderStatus | null;
  packageCount?: number | null;
  packageGroupId?: string | null;
  routeId?: string | null;
  siteCode: string;
  slammed?: boolean | null;
  weight?: number | null;
  weightUnit?: WeightUnit | null;
  deliveryAddress?: {
    __typename?: 'Address';
    address1?: string | null;
    address2?: string | null;
    address3?: string | null;
    city?: string | null;
    state?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
  } | null;
  deliveryLocation?: {
    __typename?: 'GpsLocation';
    latitude: number;
    longitude: number;
  } | null;
  deliveryWindow?: {
    __typename?: 'TimeWindow';
    endDate?: string | null;
    startDate?: string | null;
  } | null;
  pickupAddress?: {
    __typename?: 'Address';
    address1?: string | null;
    address2?: string | null;
    address3?: string | null;
    city?: string | null;
    state?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
  } | null;
  pickupLocation?: {
    __typename?: 'GpsLocation';
    latitude: number;
    longitude: number;
  } | null;
  pickupWindow?: {
    __typename?: 'TimeWindow';
    endDate?: string | null;
    startDate?: string | null;
  } | null;
  transporter?: {
    __typename?: 'Transporter';
    name?: string | null;
    phoneNumber?: string | null;
    transporterId: string;
  } | null;
};

export type OrdersPageFieldsFragment = {
  __typename?: 'OrdersPage';
  nextToken?: string | null;
  orders?: Array<{
    __typename?: 'Order';
    batched?: boolean | null;
    externalReferenceId?: string | null;
    itemCount?: number | null;
    orderId: string;
    orderStatus?: OrderStatus | null;
    packageCount?: number | null;
    packageGroupId?: string | null;
    routeId?: string | null;
    siteCode: string;
    slammed?: boolean | null;
    weight?: number | null;
    weightUnit?: WeightUnit | null;
    deliveryAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    deliveryLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    deliveryWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    pickupWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    transporter?: {
      __typename?: 'Transporter';
      name?: string | null;
      phoneNumber?: string | null;
      transporterId: string;
    } | null;
  } | null> | null;
};

export type WarmSiteMutationVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type WarmSiteMutation = {
  __typename?: 'Mutation';
  warmSite?: boolean | null;
};

export type GetRoutesForSiteQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['ID'];
}>;

export type GetRoutesForSiteQuery = {
  __typename?: 'Query';
  getRoutesForSite: {
    __typename?: 'RoutesPage';
    nextToken?: string | null;
    routes?: Array<{
      __typename?: 'Route';
      routeId: string;
      siteCode: string;
      routeName?: string | null;
      plannedDurationSeconds?: number | null;
      routeStatuses?: Array<string | null> | null;
      transporter?: {
        __typename?: 'Transporter';
        transporterId: string;
        siteCode: string;
        name?: string | null;
        phoneNumber?: string | null;
        providerType?: TransportationProviderType | null;
        companyName?: string | null;
        lastKnownLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        remainingOrders?: Array<{
          __typename?: 'Order';
          batched?: boolean | null;
          externalReferenceId?: string | null;
          itemCount?: number | null;
          orderId: string;
          orderStatus?: OrderStatus | null;
          packageCount?: number | null;
          packageGroupId?: string | null;
          routeId?: string | null;
          siteCode: string;
          slammed?: boolean | null;
          weight?: number | null;
          weightUnit?: WeightUnit | null;
          deliveryAddress?: {
            __typename?: 'Address';
            address1?: string | null;
            address2?: string | null;
            address3?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            countryCode?: string | null;
          } | null;
          deliveryLocation?: {
            __typename?: 'GpsLocation';
            latitude: number;
            longitude: number;
          } | null;
          deliveryWindow?: {
            __typename?: 'TimeWindow';
            endDate?: string | null;
            startDate?: string | null;
          } | null;
          pickupAddress?: {
            __typename?: 'Address';
            address1?: string | null;
            address2?: string | null;
            address3?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            countryCode?: string | null;
          } | null;
          pickupLocation?: {
            __typename?: 'GpsLocation';
            latitude: number;
            longitude: number;
          } | null;
          pickupWindow?: {
            __typename?: 'TimeWindow';
            endDate?: string | null;
            startDate?: string | null;
          } | null;
          transporter?: {
            __typename?: 'Transporter';
            name?: string | null;
            phoneNumber?: string | null;
            transporterId: string;
          } | null;
        } | null> | null;
        remainingStops?: Array<{
          __typename?: 'TransporterStop';
          stopType?: TransporterStopType | null;
          orderIds?: Array<string | null> | null;
        } | null> | null;
        transporterSession?: {
          __typename?: 'TransporterSession';
          transportationMode?: TransportationMode | null;
          expectedSessionEndTime?: string | null;
          schedulingType?: TransporterSchedulingType | null;
          providerReservationId?: string | null;
        } | null;
      } | null;
      orders?: Array<{
        __typename?: 'Order';
        batched?: boolean | null;
        externalReferenceId?: string | null;
        itemCount?: number | null;
        orderId: string;
        orderStatus?: OrderStatus | null;
        packageCount?: number | null;
        packageGroupId?: string | null;
        routeId?: string | null;
        siteCode: string;
        slammed?: boolean | null;
        weight?: number | null;
        weightUnit?: WeightUnit | null;
        deliveryAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        deliveryLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        deliveryWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        pickupAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        pickupLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        pickupWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        transporter?: {
          __typename?: 'Transporter';
          name?: string | null;
          phoneNumber?: string | null;
          transporterId: string;
        } | null;
      } | null> | null;
      routeStops?: Array<{
        __typename?: 'TransporterStop';
        stopType?: TransporterStopType | null;
        orderIds?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
  };
};

export type SubscribeToCreateRouteSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToCreateRouteSubscription = {
  __typename?: 'Subscription';
  subscribeToCreateRoute?: {
    __typename?: 'Route';
    routeId: string;
    siteCode: string;
    routeName?: string | null;
    plannedDurationSeconds?: number | null;
    routeStatuses?: Array<string | null> | null;
    transporter?: {
      __typename?: 'Transporter';
      transporterId: string;
      siteCode: string;
      name?: string | null;
      phoneNumber?: string | null;
      providerType?: TransportationProviderType | null;
      companyName?: string | null;
      lastKnownLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      remainingOrders?: Array<{
        __typename?: 'Order';
        batched?: boolean | null;
        externalReferenceId?: string | null;
        itemCount?: number | null;
        orderId: string;
        orderStatus?: OrderStatus | null;
        packageCount?: number | null;
        packageGroupId?: string | null;
        routeId?: string | null;
        siteCode: string;
        slammed?: boolean | null;
        weight?: number | null;
        weightUnit?: WeightUnit | null;
        deliveryAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        deliveryLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        deliveryWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        pickupAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        pickupLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        pickupWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        transporter?: {
          __typename?: 'Transporter';
          name?: string | null;
          phoneNumber?: string | null;
          transporterId: string;
        } | null;
      } | null> | null;
      remainingStops?: Array<{
        __typename?: 'TransporterStop';
        stopType?: TransporterStopType | null;
        orderIds?: Array<string | null> | null;
      } | null> | null;
      transporterSession?: {
        __typename?: 'TransporterSession';
        transportationMode?: TransportationMode | null;
        expectedSessionEndTime?: string | null;
        schedulingType?: TransporterSchedulingType | null;
        providerReservationId?: string | null;
      } | null;
    } | null;
    orders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    routeStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SubscribeToDestroyRouteSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToDestroyRouteSubscription = {
  __typename?: 'Subscription';
  subscribeToDestroyRoute?: {
    __typename?: 'Route';
    routeId: string;
    siteCode: string;
    routeName?: string | null;
    plannedDurationSeconds?: number | null;
    routeStatuses?: Array<string | null> | null;
    transporter?: {
      __typename?: 'Transporter';
      transporterId: string;
      siteCode: string;
      name?: string | null;
      phoneNumber?: string | null;
      providerType?: TransportationProviderType | null;
      companyName?: string | null;
      lastKnownLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      remainingOrders?: Array<{
        __typename?: 'Order';
        batched?: boolean | null;
        externalReferenceId?: string | null;
        itemCount?: number | null;
        orderId: string;
        orderStatus?: OrderStatus | null;
        packageCount?: number | null;
        packageGroupId?: string | null;
        routeId?: string | null;
        siteCode: string;
        slammed?: boolean | null;
        weight?: number | null;
        weightUnit?: WeightUnit | null;
        deliveryAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        deliveryLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        deliveryWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        pickupAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        pickupLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        pickupWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        transporter?: {
          __typename?: 'Transporter';
          name?: string | null;
          phoneNumber?: string | null;
          transporterId: string;
        } | null;
      } | null> | null;
      remainingStops?: Array<{
        __typename?: 'TransporterStop';
        stopType?: TransporterStopType | null;
        orderIds?: Array<string | null> | null;
      } | null> | null;
      transporterSession?: {
        __typename?: 'TransporterSession';
        transportationMode?: TransportationMode | null;
        expectedSessionEndTime?: string | null;
        schedulingType?: TransporterSchedulingType | null;
        providerReservationId?: string | null;
      } | null;
    } | null;
    orders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    routeStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SubscribeToUpdateRouteSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToUpdateRouteSubscription = {
  __typename?: 'Subscription';
  subscribeToUpdateRoute?: {
    __typename?: 'Route';
    routeId: string;
    siteCode: string;
    routeName?: string | null;
    plannedDurationSeconds?: number | null;
    routeStatuses?: Array<string | null> | null;
    transporter?: {
      __typename?: 'Transporter';
      transporterId: string;
      siteCode: string;
      name?: string | null;
      phoneNumber?: string | null;
      providerType?: TransportationProviderType | null;
      companyName?: string | null;
      lastKnownLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      remainingOrders?: Array<{
        __typename?: 'Order';
        batched?: boolean | null;
        externalReferenceId?: string | null;
        itemCount?: number | null;
        orderId: string;
        orderStatus?: OrderStatus | null;
        packageCount?: number | null;
        packageGroupId?: string | null;
        routeId?: string | null;
        siteCode: string;
        slammed?: boolean | null;
        weight?: number | null;
        weightUnit?: WeightUnit | null;
        deliveryAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        deliveryLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        deliveryWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        pickupAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        pickupLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        pickupWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        transporter?: {
          __typename?: 'Transporter';
          name?: string | null;
          phoneNumber?: string | null;
          transporterId: string;
        } | null;
      } | null> | null;
      remainingStops?: Array<{
        __typename?: 'TransporterStop';
        stopType?: TransporterStopType | null;
        orderIds?: Array<string | null> | null;
      } | null> | null;
      transporterSession?: {
        __typename?: 'TransporterSession';
        transportationMode?: TransportationMode | null;
        expectedSessionEndTime?: string | null;
        schedulingType?: TransporterSchedulingType | null;
        providerReservationId?: string | null;
      } | null;
    } | null;
    orders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    routeStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type RouteFieldsFragment = {
  __typename?: 'Route';
  routeId: string;
  siteCode: string;
  routeName?: string | null;
  plannedDurationSeconds?: number | null;
  routeStatuses?: Array<string | null> | null;
  transporter?: {
    __typename?: 'Transporter';
    transporterId: string;
    siteCode: string;
    name?: string | null;
    phoneNumber?: string | null;
    providerType?: TransportationProviderType | null;
    companyName?: string | null;
    lastKnownLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    remainingOrders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    remainingStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
    transporterSession?: {
      __typename?: 'TransporterSession';
      transportationMode?: TransportationMode | null;
      expectedSessionEndTime?: string | null;
      schedulingType?: TransporterSchedulingType | null;
      providerReservationId?: string | null;
    } | null;
  } | null;
  orders?: Array<{
    __typename?: 'Order';
    batched?: boolean | null;
    externalReferenceId?: string | null;
    itemCount?: number | null;
    orderId: string;
    orderStatus?: OrderStatus | null;
    packageCount?: number | null;
    packageGroupId?: string | null;
    routeId?: string | null;
    siteCode: string;
    slammed?: boolean | null;
    weight?: number | null;
    weightUnit?: WeightUnit | null;
    deliveryAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    deliveryLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    deliveryWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    pickupWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    transporter?: {
      __typename?: 'Transporter';
      name?: string | null;
      phoneNumber?: string | null;
      transporterId: string;
    } | null;
  } | null> | null;
  routeStops?: Array<{
    __typename?: 'TransporterStop';
    stopType?: TransporterStopType | null;
    orderIds?: Array<string | null> | null;
  } | null> | null;
};

export type RoutesPageFieldsFragment = {
  __typename?: 'RoutesPage';
  nextToken?: string | null;
  routes?: Array<{
    __typename?: 'Route';
    routeId: string;
    siteCode: string;
    routeName?: string | null;
    plannedDurationSeconds?: number | null;
    routeStatuses?: Array<string | null> | null;
    transporter?: {
      __typename?: 'Transporter';
      transporterId: string;
      siteCode: string;
      name?: string | null;
      phoneNumber?: string | null;
      providerType?: TransportationProviderType | null;
      companyName?: string | null;
      lastKnownLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      remainingOrders?: Array<{
        __typename?: 'Order';
        batched?: boolean | null;
        externalReferenceId?: string | null;
        itemCount?: number | null;
        orderId: string;
        orderStatus?: OrderStatus | null;
        packageCount?: number | null;
        packageGroupId?: string | null;
        routeId?: string | null;
        siteCode: string;
        slammed?: boolean | null;
        weight?: number | null;
        weightUnit?: WeightUnit | null;
        deliveryAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        deliveryLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        deliveryWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        pickupAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        pickupLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        pickupWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        transporter?: {
          __typename?: 'Transporter';
          name?: string | null;
          phoneNumber?: string | null;
          transporterId: string;
        } | null;
      } | null> | null;
      remainingStops?: Array<{
        __typename?: 'TransporterStop';
        stopType?: TransporterStopType | null;
        orderIds?: Array<string | null> | null;
      } | null> | null;
      transporterSession?: {
        __typename?: 'TransporterSession';
        transportationMode?: TransportationMode | null;
        expectedSessionEndTime?: string | null;
        schedulingType?: TransporterSchedulingType | null;
        providerReservationId?: string | null;
      } | null;
    } | null;
    orders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    routeStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetSiteQueryVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type GetSiteQuery = {
  __typename?: 'Query';
  getSite?: {
    __typename?: 'Site';
    siteCode: string;
    regionId?: string | null;
    serviceAreaId?: string | null;
    siteName?: string | null;
    timezone?: string | null;
    autoAssignEnabled?: boolean | null;
    isDspSite?: boolean | null;
    autoPlanningEnabled?: boolean | null;
    routesUpdatedTimeMillis?: string | null;
    transportersUpdatedTimeMillis?: string | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
  } | null;
};

export type GetAllSiteNamesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type GetAllSiteNamesQuery = {
  __typename?: 'Query';
  getAllSiteNames: {
    __typename?: 'SiteNamesPage';
    nextToken?: string | null;
    sites?: Array<{
      __typename?: 'SiteName';
      siteName?: string | null;
      siteCode?: string | null;
      serviceAreaId?: string | null;
    } | null> | null;
  };
};

export type SubscribeToUpdateSiteSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToUpdateSiteSubscription = {
  __typename?: 'Subscription';
  subscribeToUpdateSite?: {
    __typename?: 'Site';
    siteCode: string;
    regionId?: string | null;
    serviceAreaId?: string | null;
    siteName?: string | null;
    timezone?: string | null;
    autoAssignEnabled?: boolean | null;
    isDspSite?: boolean | null;
    autoPlanningEnabled?: boolean | null;
    routesUpdatedTimeMillis?: string | null;
    transportersUpdatedTimeMillis?: string | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
  } | null;
};

export type SiteFieldsFragment = {
  __typename?: 'Site';
  siteCode: string;
  regionId?: string | null;
  serviceAreaId?: string | null;
  siteName?: string | null;
  timezone?: string | null;
  autoAssignEnabled?: boolean | null;
  isDspSite?: boolean | null;
  autoPlanningEnabled?: boolean | null;
  routesUpdatedTimeMillis?: string | null;
  transportersUpdatedTimeMillis?: string | null;
  pickupAddress?: {
    __typename?: 'Address';
    address1?: string | null;
    address2?: string | null;
    address3?: string | null;
    city?: string | null;
    state?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
  } | null;
  pickupLocation?: {
    __typename?: 'GpsLocation';
    latitude: number;
    longitude: number;
  } | null;
};

export type SiteNameFieldsFragment = {
  __typename?: 'SiteName';
  siteName?: string | null;
  siteCode?: string | null;
  serviceAreaId?: string | null;
};

export type SiteNamePageFieldsFragment = {
  __typename?: 'SiteNamesPage';
  nextToken?: string | null;
  sites?: Array<{
    __typename?: 'SiteName';
    siteName?: string | null;
    siteCode?: string | null;
    serviceAreaId?: string | null;
  } | null> | null;
};

export type GetTransporterQueryVariables = Exact<{
  transporterId: Scalars['ID'];
  siteCode: Scalars['ID'];
}>;

export type GetTransporterQuery = {
  __typename?: 'Query';
  getTransporter?: {
    __typename?: 'Transporter';
    transporterId: string;
    siteCode: string;
    name?: string | null;
    phoneNumber?: string | null;
    providerType?: TransportationProviderType | null;
    companyName?: string | null;
    lastKnownLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    remainingOrders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    remainingStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
    transporterSession?: {
      __typename?: 'TransporterSession';
      transportationMode?: TransportationMode | null;
      expectedSessionEndTime?: string | null;
      schedulingType?: TransporterSchedulingType | null;
      providerReservationId?: string | null;
    } | null;
  } | null;
};

export type GetTransportersForSiteQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  siteCode: Scalars['ID'];
}>;

export type GetTransportersForSiteQuery = {
  __typename?: 'Query';
  getTransportersForSite: {
    __typename?: 'TransportersPage';
    nextToken?: string | null;
    transporters?: Array<{
      __typename?: 'Transporter';
      transporterId: string;
      siteCode: string;
      name?: string | null;
      phoneNumber?: string | null;
      providerType?: TransportationProviderType | null;
      companyName?: string | null;
      lastKnownLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      remainingOrders?: Array<{
        __typename?: 'Order';
        batched?: boolean | null;
        externalReferenceId?: string | null;
        itemCount?: number | null;
        orderId: string;
        orderStatus?: OrderStatus | null;
        packageCount?: number | null;
        packageGroupId?: string | null;
        routeId?: string | null;
        siteCode: string;
        slammed?: boolean | null;
        weight?: number | null;
        weightUnit?: WeightUnit | null;
        deliveryAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        deliveryLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        deliveryWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        pickupAddress?: {
          __typename?: 'Address';
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          city?: string | null;
          state?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
        pickupLocation?: {
          __typename?: 'GpsLocation';
          latitude: number;
          longitude: number;
        } | null;
        pickupWindow?: {
          __typename?: 'TimeWindow';
          endDate?: string | null;
          startDate?: string | null;
        } | null;
        transporter?: {
          __typename?: 'Transporter';
          name?: string | null;
          phoneNumber?: string | null;
          transporterId: string;
        } | null;
      } | null> | null;
      remainingStops?: Array<{
        __typename?: 'TransporterStop';
        stopType?: TransporterStopType | null;
        orderIds?: Array<string | null> | null;
      } | null> | null;
      transporterSession?: {
        __typename?: 'TransporterSession';
        transportationMode?: TransportationMode | null;
        expectedSessionEndTime?: string | null;
        schedulingType?: TransporterSchedulingType | null;
        providerReservationId?: string | null;
      } | null;
    } | null> | null;
  };
};

export type SubscribeToCreateTransporterSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToCreateTransporterSubscription = {
  __typename?: 'Subscription';
  subscribeToCreateTransporter?: {
    __typename?: 'Transporter';
    transporterId: string;
    siteCode: string;
    name?: string | null;
    phoneNumber?: string | null;
    providerType?: TransportationProviderType | null;
    companyName?: string | null;
    lastKnownLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    remainingOrders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    remainingStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
    transporterSession?: {
      __typename?: 'TransporterSession';
      transportationMode?: TransportationMode | null;
      expectedSessionEndTime?: string | null;
      schedulingType?: TransporterSchedulingType | null;
      providerReservationId?: string | null;
    } | null;
  } | null;
};

export type SubscribeToDestroyTransporterSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToDestroyTransporterSubscription = {
  __typename?: 'Subscription';
  subscribeToDestroyTransporter?: {
    __typename?: 'Transporter';
    transporterId: string;
    siteCode: string;
    name?: string | null;
    phoneNumber?: string | null;
    providerType?: TransportationProviderType | null;
    companyName?: string | null;
    lastKnownLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    remainingOrders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    remainingStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
    transporterSession?: {
      __typename?: 'TransporterSession';
      transportationMode?: TransportationMode | null;
      expectedSessionEndTime?: string | null;
      schedulingType?: TransporterSchedulingType | null;
      providerReservationId?: string | null;
    } | null;
  } | null;
};

export type SubscribeToUpdateTransporterSubscriptionVariables = Exact<{
  siteCode: Scalars['ID'];
}>;

export type SubscribeToUpdateTransporterSubscription = {
  __typename?: 'Subscription';
  subscribeToUpdateTransporter?: {
    __typename?: 'Transporter';
    transporterId: string;
    siteCode: string;
    name?: string | null;
    phoneNumber?: string | null;
    providerType?: TransportationProviderType | null;
    companyName?: string | null;
    lastKnownLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    remainingOrders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    remainingStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
    transporterSession?: {
      __typename?: 'TransporterSession';
      transportationMode?: TransportationMode | null;
      expectedSessionEndTime?: string | null;
      schedulingType?: TransporterSchedulingType | null;
      providerReservationId?: string | null;
    } | null;
  } | null;
};

export type TransporterSessionFieldsFragment = {
  __typename?: 'TransporterSession';
  transportationMode?: TransportationMode | null;
  expectedSessionEndTime?: string | null;
  schedulingType?: TransporterSchedulingType | null;
  providerReservationId?: string | null;
};

export type TransporterFieldsFragment = {
  __typename?: 'Transporter';
  transporterId: string;
  siteCode: string;
  name?: string | null;
  phoneNumber?: string | null;
  providerType?: TransportationProviderType | null;
  companyName?: string | null;
  lastKnownLocation?: {
    __typename?: 'GpsLocation';
    latitude: number;
    longitude: number;
  } | null;
  remainingOrders?: Array<{
    __typename?: 'Order';
    batched?: boolean | null;
    externalReferenceId?: string | null;
    itemCount?: number | null;
    orderId: string;
    orderStatus?: OrderStatus | null;
    packageCount?: number | null;
    packageGroupId?: string | null;
    routeId?: string | null;
    siteCode: string;
    slammed?: boolean | null;
    weight?: number | null;
    weightUnit?: WeightUnit | null;
    deliveryAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    deliveryLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    deliveryWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    pickupAddress?: {
      __typename?: 'Address';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    pickupLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    pickupWindow?: {
      __typename?: 'TimeWindow';
      endDate?: string | null;
      startDate?: string | null;
    } | null;
    transporter?: {
      __typename?: 'Transporter';
      name?: string | null;
      phoneNumber?: string | null;
      transporterId: string;
    } | null;
  } | null> | null;
  remainingStops?: Array<{
    __typename?: 'TransporterStop';
    stopType?: TransporterStopType | null;
    orderIds?: Array<string | null> | null;
  } | null> | null;
  transporterSession?: {
    __typename?: 'TransporterSession';
    transportationMode?: TransportationMode | null;
    expectedSessionEndTime?: string | null;
    schedulingType?: TransporterSchedulingType | null;
    providerReservationId?: string | null;
  } | null;
};

export type TransporterPageFieldsFragment = {
  __typename?: 'TransportersPage';
  nextToken?: string | null;
  transporters?: Array<{
    __typename?: 'Transporter';
    transporterId: string;
    siteCode: string;
    name?: string | null;
    phoneNumber?: string | null;
    providerType?: TransportationProviderType | null;
    companyName?: string | null;
    lastKnownLocation?: {
      __typename?: 'GpsLocation';
      latitude: number;
      longitude: number;
    } | null;
    remainingOrders?: Array<{
      __typename?: 'Order';
      batched?: boolean | null;
      externalReferenceId?: string | null;
      itemCount?: number | null;
      orderId: string;
      orderStatus?: OrderStatus | null;
      packageCount?: number | null;
      packageGroupId?: string | null;
      routeId?: string | null;
      siteCode: string;
      slammed?: boolean | null;
      weight?: number | null;
      weightUnit?: WeightUnit | null;
      deliveryAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      deliveryLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      deliveryWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      pickupAddress?: {
        __typename?: 'Address';
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        city?: string | null;
        state?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      pickupLocation?: {
        __typename?: 'GpsLocation';
        latitude: number;
        longitude: number;
      } | null;
      pickupWindow?: {
        __typename?: 'TimeWindow';
        endDate?: string | null;
        startDate?: string | null;
      } | null;
      transporter?: {
        __typename?: 'Transporter';
        name?: string | null;
        phoneNumber?: string | null;
        transporterId: string;
      } | null;
    } | null> | null;
    remainingStops?: Array<{
      __typename?: 'TransporterStop';
      stopType?: TransporterStopType | null;
      orderIds?: Array<string | null> | null;
    } | null> | null;
    transporterSession?: {
      __typename?: 'TransporterSession';
      transportationMode?: TransportationMode | null;
      expectedSessionEndTime?: string | null;
      schedulingType?: TransporterSchedulingType | null;
      providerReservationId?: string | null;
    } | null;
  } | null> | null;
};

export const AssignmentResponseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssignmentResponseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssignmentResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignmentResponseFieldsFragment, unknown>;
export const AddressFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressFieldsFragment, unknown>;
export const GpsLocationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GpsLocationFieldsFragment, unknown>;
export const TimeWindowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TimeWindowFieldsFragment, unknown>;
export const OrderFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderFieldsFragment, unknown>;
export const OrdersPageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrdersPageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OrdersPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrdersPageFieldsFragment, unknown>;
export const TransporterStopFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransporterStopFieldsFragment, unknown>;
export const TransporterSessionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransporterSessionFieldsFragment, unknown>;
export const TransporterFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransporterFieldsFragment, unknown>;
export const RouteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RouteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedDurationSeconds' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeStatuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routeStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RouteFieldsFragment, unknown>;
export const RoutesPageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoutesPageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RoutesPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RouteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RouteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedDurationSeconds' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeStatuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routeStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoutesPageFieldsFragment, unknown>;
export const SiteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Site' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceAreaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoAssignEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDspSite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoPlanningEnabled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routesUpdatedTimeMillis' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportersUpdatedTimeMillis' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteFieldsFragment, unknown>;
export const SiteNameFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteNameFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SiteName' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceAreaId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteNameFieldsFragment, unknown>;
export const SiteNamePageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteNamePageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SiteNamesPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SiteNameFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteNameFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SiteName' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceAreaId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteNamePageFieldsFragment, unknown>;
export const TransporterPageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterPageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransportersPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransporterPageFieldsFragment, unknown>;
export const UiLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uiLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uiLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'logs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UiLogsMutation, UiLogsMutationVariables>;
export const AssignOrdersToTransporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'assignOrdersToTransporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orders' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OrderLevelAssignmentInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'transporterId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignOrdersToTransporter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orders' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orders' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transporterId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssignmentResponseFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssignmentResponseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssignmentResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignOrdersToTransporterMutation,
  AssignOrdersToTransporterMutationVariables
>;
export const UnassignOrdersFromTransporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unassignOrdersFromTransporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orders' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OrderLevelAssignmentInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'transporterId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unassignOrdersFromTransporter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orders' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orders' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transporterId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssignmentResponseFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssignmentResponseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssignmentResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnassignOrdersFromTransporterMutation,
  UnassignOrdersFromTransporterMutationVariables
>;
export const GetOrdersForSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrdersForSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrdersForSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrdersPageFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrdersPageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OrdersPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrdersForSiteQuery,
  GetOrdersForSiteQueryVariables
>;
export const SubscribeToCreateOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToCreateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToCreateOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToCreateOrderSubscription,
  SubscribeToCreateOrderSubscriptionVariables
>;
export const SubscribeToDestroyOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToDestroyOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToDestroyOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToDestroyOrderSubscription,
  SubscribeToDestroyOrderSubscriptionVariables
>;
export const SubscribeToUpdateOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToUpdateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToUpdateOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToUpdateOrderSubscription,
  SubscribeToUpdateOrderSubscriptionVariables
>;
export const WarmSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'warmSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warmSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WarmSiteMutation, WarmSiteMutationVariables>;
export const GetRoutesForSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRoutesForSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRoutesForSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RoutesPageFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RouteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedDurationSeconds' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeStatuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routeStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoutesPageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RoutesPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RouteFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRoutesForSiteQuery,
  GetRoutesForSiteQueryVariables
>;
export const SubscribeToCreateRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToCreateRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToCreateRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RouteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RouteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedDurationSeconds' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeStatuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routeStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToCreateRouteSubscription,
  SubscribeToCreateRouteSubscriptionVariables
>;
export const SubscribeToDestroyRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToDestroyRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToDestroyRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RouteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RouteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedDurationSeconds' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeStatuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routeStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToDestroyRouteSubscription,
  SubscribeToDestroyRouteSubscriptionVariables
>;
export const SubscribeToUpdateRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToUpdateRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToUpdateRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RouteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RouteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Route' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedDurationSeconds' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeStatuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routeStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToUpdateRouteSubscription,
  SubscribeToUpdateRouteSubscriptionVariables
>;
export const GetSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SiteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Site' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceAreaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoAssignEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDspSite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoPlanningEnabled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routesUpdatedTimeMillis' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportersUpdatedTimeMillis' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSiteQuery, GetSiteQueryVariables>;
export const GetAllSiteNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllSiteNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllSiteNames' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SiteNamePageFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteNameFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SiteName' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceAreaId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteNamePageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SiteNamesPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SiteNameFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllSiteNamesQuery,
  GetAllSiteNamesQueryVariables
>;
export const SubscribeToUpdateSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToUpdateSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToUpdateSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SiteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Site' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceAreaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoAssignEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDspSite' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoPlanningEnabled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routesUpdatedTimeMillis' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportersUpdatedTimeMillis' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToUpdateSiteSubscription,
  SubscribeToUpdateSiteSubscriptionVariables
>;
export const GetTransporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTransporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'transporterId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransporter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transporterId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTransporterQuery, GetTransporterQueryVariables>;
export const GetTransportersForSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTransportersForSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransportersForSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterPageFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterPageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransportersPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransportersForSiteQuery,
  GetTransportersForSiteQueryVariables
>;
export const SubscribeToCreateTransporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToCreateTransporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToCreateTransporter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToCreateTransporterSubscription,
  SubscribeToCreateTransporterSubscriptionVariables
>;
export const SubscribeToDestroyTransporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToDestroyTransporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToDestroyTransporter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToDestroyTransporterSubscription,
  SubscribeToDestroyTransporterSubscriptionVariables
>;
export const SubscribeToUpdateTransporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'subscribeToUpdateTransporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeToUpdateTransporter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GpsLocationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GpsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeWindowFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TimeWindow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'batched' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferenceId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupWindow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TimeWindowFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slammed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transporterId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterStopFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterStop' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stopType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterSessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TransporterSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportationMode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expectedSessionEndTime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'schedulingType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providerReservationId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransporterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transporter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'transporterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastKnownLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GpsLocationFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingStops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterStopFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transporterSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransporterSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeToUpdateTransporterSubscription,
  SubscribeToUpdateTransporterSubscriptionVariables
>;
