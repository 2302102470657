import * as _ from 'lodash';
import { Route } from '@amzn/gsf-dispatcher-schema';
import { action, makeObservable, observable } from 'mobx';
import Logger from '../logging/Log';
import OrderGroup from '../map/model/OrderGroup';
import StateCopier from '../util/StateCopier';
import TransporterGroup from '../map/model/TransporterGroup';

class RouteStore {
  routes: Route[] = [];

  filteredRoutes: Route[] = [];

  sortedRoutes: Route[] = [];

  selectedRouteId: string;

  selectedRouteOrderGroups: OrderGroup[] = [];

  selectedRouteTransporterGroups: TransporterGroup[] = [];

  constructor() {
    makeObservable(this, {
      routes: observable,
      filteredRoutes: observable,
      sortedRoutes: observable,
      selectedRouteId: observable,
      selectedRouteOrderGroups: observable,
      selectedRouteTransporterGroups: observable,
      setRoutes: action,
      setFilteredRoutes: action,
      setSortedRoutes: action,
      setSelectedRouteOrderGroups: action,
      setSelectedRouteTransporterGroups: action,
      setSelectedRouteId: action,
      removeRoute: action,
      updateRoute: action,
    });
  }

  setRoutes(routes: Route[]) {
    this.routes = routes;
  }

  setFilteredRoutes(routes: Route[]) {
    this.filteredRoutes = routes;
  }

  setSortedRoutes(routes: Route[]) {
    this.sortedRoutes = routes;
  }

  setSelectedRouteId(routeId: string) {
    this.selectedRouteId = routeId;
  }

  setSelectedRouteOrderGroups(orderGroups: OrderGroup[]) {
    this.selectedRouteOrderGroups = orderGroups;
  }

  setSelectedRouteTransporterGroups(transporterGroups: TransporterGroup[]) {
    this.selectedRouteTransporterGroups = transporterGroups;
  }

  removeRoute(route: Route) {
    const existingRoute = this.findExistingRoute(route.routeId);

    if (existingRoute) {
      const existingRoutes = _.clone(this.routes);
      const routeIndex = existingRoutes.indexOf(existingRoute);
      existingRoutes.splice(routeIndex, 1);
      Logger.debug(
        `routeStore.removeRoute: removing existing route (${
          existingRoutes.length
        }) ${JSON.stringify(existingRoute)}`
      );
      this.setRoutes(existingRoutes);
    }
  }

  updateRoute(route: Route) {
    const existingRoute = this.findExistingRoute(route.routeId);
    if (existingRoute) {
      const changedAttributes = StateCopier.copyState(route, existingRoute);
      if (changedAttributes.length > 0) {
        Logger.debug(
          `routeStore.updateRoute: updated existing route [${changedAttributes}]: ${JSON.stringify(
            existingRoute
          )}`
        );
      }
    } else {
      const existingRoutes = _.clone(this.routes);
      existingRoutes.push(route);
      Logger.debug(
        `routeStore.updateRoute: adding new route (${
          existingRoutes.length
        }) ${JSON.stringify(route)}`
      );
      this.setRoutes(existingRoutes);
    }
  }

  private findExistingRoute(routeId: string): Route | undefined {
    return this.routes.find((r) => r.routeId === routeId);
  }
}
export default new RouteStore();
