import {
  ERROR_MESSAGE_TIMEOUT,
  SUCCESS_MESSAGE_TIMEOUT,
} from '../util/TimeHelper';
import AssignmentHelper from '../util/AssignmentHelper';
import ErrorHelper from '../util/ErrorHelper';
import Log from '../logging/Log';
import MessagesController from './MessagesController';
import OperationController from './OperationController';
import OrderService from '../services/OrderService';
import Performance from '../logging/Performance';
import StringHelper from '../util/StringHelper';
import assignmentStore from '../stores/assignmentStore';
import orderStore from '../stores/orderStore';
import siteStore from '../stores/siteStore';

const operationName = 'orderUnassignment';

export default class UnassignmentController {
  static async startUnassignment(): Promise<void> {
    assignmentStore.clearConfirmationMessages();
    const confirmationMessages =
      AssignmentHelper.determineUnassignmentConfirmationMessages();
    if (confirmationMessages.length === 0) {
      // nothing for the user to confirm, so call confirm assignment
      await UnassignmentController.confirmUnassignment();
    } else {
      // cause the confirmation dialog to popup
      Log.info(
        `Confirmation messages: [${confirmationMessages}]`,
        operationName
      );
      assignmentStore.setConfirmationMessages(confirmationMessages);
    }
  }

  static async cancelUnassignment(): Promise<void> {
    Log.info('Cancel un-assignment', operationName);
    assignmentStore.clearConfirmationMessages();
  }

  static async confirmUnassignment(): Promise<void> {
    const startTime = Date.now();
    assignmentStore.clearConfirmationMessages();
    OperationController.startOperation('Unassign transporter');
    const { selectedSite } = siteStore;
    const { siteCode } = selectedSite;
    const orderIdInputs = AssignmentHelper.gatherOrderIdsForAssignment();
    const orderIds: string[] = orderIdInputs.map((o) => o.orderId);
    try {
      const assignmentResponse =
        await OrderService.unassignOrdersFromTransporter(
          siteCode,
          orderIdInputs
        );
      OperationController.completeOperation();

      // gather the results and determine if need to inform user of any failures
      const successfulOrderIds: string[] = [];
      const failedOrderIds: string[] = [];
      const { errorMessage, successful, failed } = assignmentResponse;
      if (errorMessage) {
        failedOrderIds.push(...orderIds);
      } else {
        successfulOrderIds.push(...successful);
        failedOrderIds.push(...failed);
      }
      if (failedOrderIds.length > 0 && successfulOrderIds.length === 0) {
        const msg = `All un-assignments failed${
          errorMessage ? `: ${errorMessage}` : ''
        }`;
        MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
        Log.error(msg, operationName);
      } else if (failedOrderIds.length > 0 && successfulOrderIds.length > 0) {
        const msg =
          'Partial order un-assignment failure: ' +
          `${StringHelper.makeCountDisplay(
            successfulOrderIds.length,
            'order'
          )} succeeded, but ` +
          `${StringHelper.makeCountDisplay(
            failedOrderIds.length,
            'order'
          )} failed.`;
        MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
        Log.error(msg, operationName);
      }

      // remove the successful order ids from the selected order ids in case the user wants to try again with
      // just the failed order ids
      orderStore.removeSelectedOrderIds(successfulOrderIds);

      if (failedOrderIds.length === 0) {
        const msg = 'Successful un-assignment';
        MessagesController.addSuccessMessage(msg, SUCCESS_MESSAGE_TIMEOUT);
        Log.info(msg, operationName);
      }
    } catch (error) {
      const msg = `General failure during un-assignment: ${ErrorHelper.getErrorMessage(
        error
      )}`;
      OperationController.completeOperation();
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, operationName);
    }
    Performance.log(operationName, startTime);
  }
}
