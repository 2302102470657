import viewStore from '../stores/viewStore';

export default class ViewController {
  static handleMapViewChange(mapView: string) {
    viewStore.setMapView(mapView);
  }

  static handleOrderViewsChange(orderViews: string[]) {
    viewStore.setOrderViews(orderViews);
  }

  static toggleRoutesCollapsed() {
    const routesCollapsed = viewStore.routesCollapsed;
    viewStore.setRoutesCollapsed(!routesCollapsed);
  }

  static toggleGroupByAssignmentStatus() {
    const groupByAssignmentStatus = viewStore.groupByAssignmentStatus;
    viewStore.setGroupByAssignmentStatus(!groupByAssignmentStatus);
  }
}
