import * as _ from 'lodash';
import {
  ORDER_VIEW_ROUTES,
  ORDER_VIEW_UNPLANNED_ORDERS,
} from '../../util/ViewConstants';
import { Order, Route } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import Badge from '@amzn/meridian/badge';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import OrderController from '../../controllers/OrderController';
import React from 'react';
import Row from '@amzn/meridian/row';
import StringHelper from 'util/StringHelper';
import Text from '@amzn/meridian/text';
import ViewController from '../../controllers/ViewController';
import collapseTokens from '@amzn/meridian-tokens/base/icon/collapse';
import expandTokens from '@amzn/meridian-tokens/base/icon/expand';
import orderStore from '../../stores/orderStore';
import routeStore from '../../stores/routeStore';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import viewStore from '../../stores/viewStore';

export interface RoutesAndOrdersHeaderProps {
  routes: Route[];
  orders: Order[];
}

class RoutesAndOrdersHeaderComp extends React.Component<RoutesAndOrdersHeaderProps> {
  render() {
    const { routes, orders } = this.props;
    const { routesCollapsed, orderViews } = viewStore;
    const showRoutes = _.includes(orderViews, ORDER_VIEW_ROUTES);
    const showOrders = _.includes(orderViews, ORDER_VIEW_UNPLANNED_ORDERS);
    const totalRoutes = routeStore.routes;
    const totalOrders = orderStore.orders;
    const { selectedOrderIds } = orderStore;
    return (
      <Column spacing="200">
        {(showRoutes || showOrders) && (
          <Row
            wrap="down"
            spacingInset="100"
            alignmentHorizontal="center"
            spacing="400"
          >
            {showRoutes && (
              <Text type="h300" className="nowrap">
                {StringHelper.makeCountOfCountDisplay(
                  routes.length,
                  totalRoutes.length,
                  'Route'
                )}
              </Text>
            )}
            {showOrders && (
              <Text type="h300" className="nowrap">
                {StringHelper.makeCountOfCountDisplay(
                  orders.length,
                  totalOrders.length,
                  'Order'
                )}
              </Text>
            )}
            <Button
              size="small"
              type="icon"
              onClick={() => ViewController.toggleRoutesCollapsed()}
              propagateClickEvent={false}
            >
              <Icon tokens={routesCollapsed ? expandTokens : collapseTokens} />
            </Button>
            <Row spacing="none">
              <Button
                size="small"
                type="icon"
                onClick={() => OrderController.clearAllOrders()}
                propagateClickEvent={false}
              >
                <Icon tokens={trashTokens} />
              </Button>
              {selectedOrderIds.length > 0 && (
                <Text type="h300" className="nowrap">
                  <Badge value={selectedOrderIds.length} type="error" />
                </Text>
              )}
            </Row>
          </Row>
        )}
      </Column>
    );
  }
}

export default observer(RoutesAndOrdersHeaderComp);
