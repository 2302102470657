import {
  ERROR_MESSAGE_TIMEOUT,
  SUCCESS_MESSAGE_TIMEOUT,
} from '../util/TimeHelper';
import AssignmentHelper from '../util/AssignmentHelper';
import ErrorHelper from '../util/ErrorHelper';
import Log from '../logging/Log';
import MessagesController from './MessagesController';
import OperationController from './OperationController';
import OrderService from '../services/OrderService';
import Performance from '../logging/Performance';
import StringHelper from '../util/StringHelper';
import assignmentStore from '../stores/assignmentStore';
import orderStore from '../stores/orderStore';
import siteStore from '../stores/siteStore';
import transporterStore from '../stores/transporterStore';

const operationName = 'orderAssignment';

export default class AssignmentController {
  static async startAssignment(): Promise<void> {
    assignmentStore.clearConfirmationMessages();
    const confirmationMessages =
      AssignmentHelper.determineAssignmentConfirmationMessages();
    if (confirmationMessages.length === 0) {
      // nothing for the user to confirm, so call confirm assignment
      await AssignmentController.confirmAssignment();
    } else {
      // cause the confirmation dialog to popup
      Log.info(
        `Confirmation messages: [${confirmationMessages}]`,
        operationName
      );
      assignmentStore.setConfirmationMessages(confirmationMessages);
    }
  }

  static async cancelAssignment(): Promise<void> {
    Log.info('Cancel assignment', operationName);
    assignmentStore.clearConfirmationMessages();
  }

  static async confirmAssignment(): Promise<void> {
    const startTime = Date.now();
    assignmentStore.clearConfirmationMessages();
    OperationController.startOperation('Assigning transporter');
    const { selectedTransporterId } = transporterStore;
    const { selectedSite } = siteStore;
    const { siteCode } = selectedSite;
    const orderIdInputs = AssignmentHelper.gatherOrderIdsForAssignment();
    const orderIds: string[] = orderIdInputs.map((o) => o.orderId);
    try {
      const assignmentResponse = await OrderService.assignOrdersToTransporter(
        siteCode,
        orderIdInputs,
        selectedTransporterId
      );
      OperationController.completeOperation();

      // gather the results and determine if need to inform user of any failures
      const successfulOrderIds: string[] = [];
      const failedOrderIds: string[] = [];
      const { errorMessage, successful, failed } = assignmentResponse;
      if (errorMessage) {
        failedOrderIds.push(...orderIds);
      } else {
        successfulOrderIds.push(...successful);
        failedOrderIds.push(...failed);
      }
      if (failedOrderIds.length > 0 && successfulOrderIds.length === 0) {
        const msg = `All assignments failed${
          errorMessage ? `: ${errorMessage}` : ''
        }`;
        MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
        Log.error(msg, operationName);
      } else if (failedOrderIds.length > 0 && successfulOrderIds.length > 0) {
        const msg =
          'Partial order assignment failure: ' +
          `${StringHelper.makeCountDisplay(
            successfulOrderIds.length,
            'order'
          )} succeeded, but ` +
          `${StringHelper.makeCountDisplay(
            failedOrderIds.length,
            'order'
          )} failed.`;
        MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
        Log.error(msg, operationName);
      }

      // remove the successful order ids from the selected order ids in case the user wants to try again with
      // just the failed order ids
      orderStore.removeSelectedOrderIds(successfulOrderIds);

      if (failedOrderIds.length === 0) {
        const msg = 'Successful assignment';
        MessagesController.addSuccessMessage(msg, SUCCESS_MESSAGE_TIMEOUT);
        Log.info(msg, operationName);
      }
    } catch (error) {
      const msg = `General failure during assignment: ${ErrorHelper.getErrorMessage(
        error
      )}`;
      OperationController.completeOperation();
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, operationName);
    }
    Performance.log(operationName, startTime);
  }
}
