import './RouteComp.css';
import * as _ from 'lodash';
import { PICKUP_WINDOW_END_TIME } from '../../util/SortConstants';
import { Route } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import OrderComp from '../order/OrderComp';
import OrderHelper from '../../util/OrderHelper';
import React from 'react';
import RemainingTimeComp from '../common/RemainingTimeComp';
import RouteController from '../../controllers/RouteController';
import RouteHelper from '../../util/RouteHelper';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import TimeHelper from '../../util/TimeHelper';
import Tooltip from '@amzn/meridian/tooltip';
import TransporterController from '../../controllers/TransporterController';
import TransporterHelper from '../../util/TransporterHelper';
import heavyBagImage from '../../assets/images/heavy.svg';
import locationTokens from '@amzn/meridian-tokens/base/icon/location';
import phoneTokens from '@amzn/meridian-tokens/base/icon/phone';
import routeStore from '../../stores/routeStore';
import sortStore from '../../stores/sortStore';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import viewStore from 'stores/viewStore';

export interface RouteProps {
  route: Route;
}

class RouteComp extends React.Component<RouteProps> {
  render() {
    const { route } = this.props;
    const { routesCollapsed } = viewStore;
    const estimatedTimeOnRoadForRoute = TimeHelper.secondsToHoursString(
      Math.abs(route.plannedDurationSeconds)
    );
    const { selectedRouteId } = routeStore;
    const isSelectedRoute = selectedRouteId === route.routeId;
    const transporterName = TransporterHelper.getTransporterName(
      route.transporter
    );
    const isRouteAssignmentSplit = RouteHelper.isRouteAssignmentSplit(route);
    const isRouteAssignedOrAllOrdersAssigned =
      RouteHelper.isRouteAssignedOrAllOrdersAssigned(route);
    const isRoutePicked = RouteHelper.areAllOrdersOnRoutePicked(route);
    const totalOrderCount = RouteHelper.calculateTotalOrderCount(route);
    const totalPackageCount = RouteHelper.calculateTotalPackageCount(route);
    const totalPackageWeight = RouteHelper.calculateTotalPackageWeight(route);

    // setting a CSS variables so that the route header vertical color gradient colors can be set
    const routeHeaderColor = this.getRouteHeaderColor();
    const routeHeaderStyle = {
      '--topColor': routeHeaderColor,
      '--bottomColor': isRouteAssignedOrAllOrdersAssigned
        ? '#777777'
        : routeHeaderColor,
    } as React.CSSProperties;
    const routeHeaderClassName = 'route-header-background';
    const { selectedSortCode } = sortStore;
    const ordersForRoute = RouteHelper.getOrders([route]);
    const hasHeavyOrders = _.some(ordersForRoute, (o) =>
      OrderHelper.isOrderHeavy(o)
    );
    const hasOneHourOrders = _.some(ordersForRoute, (o) =>
      OrderHelper.isOneHourDeliveryWindow(o)
    );
    const slammedPercent = RouteHelper.calculatePercentSlammed(route);

    const orderComps = routesCollapsed
      ? []
      : route.orders.map((o) => (
          <OrderComp
            key={`route-${route.routeId}-order-${o.orderId}`}
            order={o}
          />
        ));

    return (
      <div onClick={() => RouteController.routeClicked(route)}>
        <Box type="outline" spacingInset="200" backgroundColor="lightgrey">
          <Column spacing="200">
            <div
              className={`border-radius ${routeHeaderClassName}`}
              style={routeHeaderStyle}
            >
              <Box
                spacingInset="300"
                className={
                  isSelectedRoute ? 'div-selected' : 'div-not-selected'
                }
              >
                <Column spacing="300">
                  <Row wrap="down" widths={['fit', 'fit', 'fill']}>
                    <Column>
                      <Row spacing="200">
                        <Text type="h100" color="inverted">
                          {`Route: ${route.routeName}`}
                        </Text>
                      </Row>
                    </Column>
                    <Column>
                      <Text type="h100" color="inverted">
                        {`(${estimatedTimeOnRoadForRoute})`}
                      </Text>
                    </Column>
                    <Column alignmentHorizontal="end">
                      <Row className="nowrap">
                        {hasHeavyOrders && (
                          <Tooltip position="end" title="Heavy orders">
                            <img
                              className="heavy-order-image"
                              src={heavyBagImage}
                            />
                          </Tooltip>
                        )}
                        {hasOneHourOrders && (
                          <Tooltip
                            position="end"
                            title="One hr delivery window"
                          >
                            <Text type="h300" color="inverted">
                              1 hr
                            </Text>
                          </Tooltip>
                        )}
                      </Row>
                    </Column>
                  </Row>

                  <Row>
                    <Text type="h100" color="inverted">
                      {selectedSortCode === PICKUP_WINDOW_END_TIME
                        ? `Pickup Window: ${RouteHelper.getPickupWindowForRoute(
                            route
                          )}`
                        : `Delivery Window: ${RouteHelper.getDeliveryWindowForRoute(
                            route
                          )}`}
                    </Text>
                  </Row>

                  <Row spacing="200">
                    {selectedSortCode === PICKUP_WINDOW_END_TIME ? (
                      <RemainingTimeComp
                        color="inverted"
                        endTime={OrderHelper.getLatestOrderPickupEndTime(
                          ordersForRoute
                        )}
                      />
                    ) : (
                      <RemainingTimeComp
                        color="inverted"
                        endTime={OrderHelper.getLatestOrderDeliveryEndTime(
                          ordersForRoute
                        )}
                      />
                    )}
                    <Text type="h100" color="inverted">
                      {`- [Slammed: ${slammedPercent}]`}
                    </Text>
                  </Row>

                  <Row wrap="down">
                    <Text type="h100" color="inverted">
                      {`(Deliveries: ${totalOrderCount}) - (Packages: ${totalPackageCount})`}
                    </Text>
                  </Row>

                  <Row wrap="down" alignmentHorizontal="justify">
                    <Text type="h100" color="inverted">
                      {`(Weight: ${totalPackageWeight})`}
                    </Text>
                    <Button
                      size="small"
                      backdropColor="inverted"
                      type="icon"
                      onClick={() =>
                        RouteController.deselectAllOrdersOfRoute(route)
                      }
                      propagateClickEvent={false}
                    >
                      <Icon tokens={trashTokens} />
                    </Button>
                  </Row>

                  {(transporterName || isRouteAssignmentSplit) && (
                    <Row wrap="down" spacing="200">
                      <Column>
                        <Text type="h100" color="inverted">
                          {`${
                            isRoutePicked ? 'Picked Up By' : 'Assigned To: '
                          } ${
                            isRouteAssignmentSplit
                              ? 'Route Split (see order level)'
                              : transporterName
                          }`}
                        </Text>
                      </Column>
                      {transporterName && (
                        <Column>
                          <Button
                            size="small"
                            type="icon"
                            propagateClickEvent={false}
                            backdropColor={'white'}
                            onClick={() =>
                              TransporterController.handleTransporterLocationClick(
                                route.transporter
                              )
                            }
                          >
                            <Icon tokens={locationTokens} />
                          </Button>
                        </Column>
                      )}
                      {transporterName && (
                        <Column>
                          <Button
                            size="small"
                            type="icon"
                            propagateClickEvent={false}
                            backdropColor={'white'}
                            onClick={() =>
                              TransporterController.handleTransporterPhoneClick(
                                route.transporter
                              )
                            }
                          >
                            <Icon tokens={phoneTokens} />
                          </Button>
                        </Column>
                      )}
                    </Row>
                  )}
                </Column>
              </Box>
            </div>
            {orderComps}
          </Column>
        </Box>
      </div>
    );
  }

  private getRouteHeaderColor(): string {
    const { route } = this.props;
    if (RouteHelper.isAmazonPickupRoute(route)) {
      return '#EC0300';
    }
    if (RouteHelper.isThirdPartyPickupRoute(route)) {
      return '#09A9EE';
    }
    if (RouteHelper.isAmazonThenThirdPartyPickupRoute(route)) {
      return '#FFAC1C';
    }
    if (RouteHelper.isThirdPartyThenAmazonPickupRoute(route)) {
      return '#FF69B4';
    }
    return '#FFFFFF';
  }
}

export default observer(RouteComp);
