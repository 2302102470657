import * as _ from 'lodash';
import { MAP_VIEW_ROUTES } from '../util/ViewConstants';
import { Route } from '@amzn/gsf-dispatcher-schema';
import GeospatialHelper from '../map/GeospatialHelper';
import MapObject from '../map/MapObject';
import OrderController from './OrderController';
import RouteHelper from '../util/RouteHelper';
import orderStore from '../stores/orderStore';
import routeStore from '../stores/routeStore';
import viewStore from '../stores/viewStore';

export default class RouteController {
  static routeClicked(route: Route) {
    const currentlySelectedRouteId = routeStore.selectedRouteId;
    const routeId = route.routeId;
    const orderIds = RouteHelper.getOrders([route]).map((r) => r.orderId);
    if (currentlySelectedRouteId === routeId) {
      routeStore.setSelectedRouteId(undefined);
      orderStore.removeSelectedOrderIds(orderIds);
      if (RouteController.interactWithMap()) {
        MapObject.restoreLastCenterAndResolution();
      }
    } else {
      if (!!currentlySelectedRouteId) {
        const currentlySelectedRoute = routeStore.routes.find(
          (r) => r.routeId === currentlySelectedRouteId
        );
        if (currentlySelectedRoute) {
          const orderIdsForCurrentlySelectedRoute = RouteHelper.getOrders([
            currentlySelectedRoute,
          ]).map((o) => o.orderId);
          orderStore.removeSelectedOrderIds(orderIdsForCurrentlySelectedRoute);
        }
      }
      routeStore.setSelectedRouteId(routeId);
      orderStore.addSelectedOrderIds(orderIds);
      viewStore.setMapView(MAP_VIEW_ROUTES);
    }
  }

  static deselectAllOrdersOfRoute(route: Route) {
    const orderIds = RouteHelper.getOrders([route]).map((o) => o.orderId);
    orderStore.removeSelectedOrderIds(orderIds);
  }

  static setMapExtentsToSelectedRoute() {
    if (RouteController.interactWithMap()) {
      const currentlySelectedRouteId = routeStore.selectedRouteId;
      if (currentlySelectedRouteId) {
        const selectedRoute = routeStore.routes.find(
          (r) => r.routeId === currentlySelectedRouteId
        );
        if (selectedRoute) {
          MapObject.setMapExtent(
            GeospatialHelper.getExtentsForRoute(selectedRoute)
          );
        }
      }
    }
  }

  static handleDestroyRoute(route: Route): void {
    routeStore.removeRoute(route);
  }

  static handleUpdateRoute(route: Route): void {
    RouteController.fixRouteData(route);
    if (route.routeId) {
      routeStore.updateRoute(route);
    }
  }

  /**
   * If a route cannot be fixed, the set the routeId to undefined and it will be
   * filtered out
   * @param route
   */
  static fixRouteData(route: Route): void {
    route.orders = route.orders || [];
    route.orders = route.orders.filter((o) => {
      OrderController.fixOrderData(o);
      return o.orderId;
    });
    const orderIds = route.orders.map((o) => o.orderId);

    route.routeStops = route.routeStops || [];
    route.routeStops.forEach((stop) => {
      stop.orderIds = stop.orderIds || [];
      stop.orderIds = stop.orderIds.filter((orderId) =>
        _.includes(orderIds, orderId)
      );
    });
    route.routeStops = route.routeStops.filter(
      (stop) => stop.orderIds.length > 0
    );

    RouteHelper.setRouteIdOnRouteOrders(route);

    if (route.orders.length === 0 || route.routeStops.length === 0) {
      route.routeId = undefined;
    }
  }

  private static interactWithMap() {
    const { mapView } = viewStore;
    return mapView === MAP_VIEW_ROUTES;
  }
}
