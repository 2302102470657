import { PREFIX_COMPANY_NAME } from '../util/FilterConstants';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import FilterHelper, { FilterEvaluator } from '../util/FilterHelper';
import filterStore from '../stores/filterStore';

export default class TransporterFilterController {
  static filterTransporters(allTransporters: Transporter[]): Transporter[] {
    return allTransporters.filter((t) =>
      TransporterFilterController.doesTransporterPassFilter(t)
    );
  }

  private static doesTransporterPassFilter(transporter: Transporter): boolean {
    const { selectedTransporterFilterCodes } = filterStore;

    const evaluators: FilterEvaluator<Transporter>[] = [];

    // company name filter
    evaluators.push({
      prefix: PREFIX_COMPANY_NAME,
      evaluatorFunction: (transporter: Transporter, codeValue: string) => {
        return codeValue === transporter.companyName;
      },
    });

    return FilterHelper.passAllFilterGroups(
      transporter,
      selectedTransporterFilterCodes,
      evaluators
    );
  }
}
